import React, {useState, useEffect} from 'react';
import Layout from '../homelayout/newlayout';
import {useProjects} from '../useContentful';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';



function Team() {
	const [projects, setProjects] = useState([]);
    const {getProjects} = useProjects();
    
    useEffect(() => {
        getProjects().then((response) => {
            setProjects(response?.items);
        })
    })
  return (
   <Layout>
         <Helmet>
        <title>Projects | Dyslexia</title>
      </Helmet>
      <section class="page-header" style={{backgroundImage: "url(https://res.cloudinary.com/dedfrilse/image/upload/v1681640262/Banner_image-01.png)"}}>
	  <div className='page-header-opacity'/>
			<div class="container">
				<ul class="list-unstyled breadcrumb-one">
					<li><a href="/">Home</a></li>
					<li><span>Projects</span></li>
				</ul>
				<h2 class="page-header__title">Projects</h2>
			</div>
		</section>
        <section class="sec-pad-top sec-pad-bottom about-two">
			<img src="https://paroti-html.vercel.app/assets/images/shapes/about-2-1.png" class="about-two__shape-1 float-bob-x" alt=""/>
			<div class="container">
				<div class="row gutter-y-60">
					<div class="col-md-12 col-lg-6">
						<div class="about-two__image">
							<div class="about-two__image__shape-1"></div>
							<div class="about-two__image__shape-2"></div>
							<div class="about-two__image__shape-3"></div>
							<img src="https://res.cloudinary.com/dedfrilse/image/upload/v1680086616/dsylexia/500x591-Project_Page.png" class="wow fadeInLeft"
								data-wow-duration="1500ms" alt=""/>
							{/* <div class="about-two__image__caption">
								<h3 class="about-two__image__caption__count count-box">
									<span class="count-text" data-stop="30" data-speed="1500"></span>+
								</h3>
								<p class="about-two__image__caption__text">Years of work
									expeirece</p>
							</div> */}
						</div>
					</div>
					<div class="col-md-12 col-lg-6">
						<div class="about-two__content">
							<div class="sec-title">
								<p class="sec-title__tagline">Join our Community</p>
								<h2 class="sec-title__title">Let’s reshape how we teach them</h2>
							</div>
							<p class="about-two__text">We conduct various programs and projects to raise awareness about dyslexia among parents, guardians,and teachers, which are:</p>
							{/* <ul class="list-unstyled about-two__info">
								<li class="about-two__info__item" style={{"--accent-color": "#8139e7"}}>
									<i class="paroti-icon-solidarity"></i>
									<h3 class="about-two__info__title">Our Values</h3>
								</li>
							</ul> */}
							<ul class="list-unstyled about-two__list">
								<li>
									<i class="fa fa-check-circle"></i>
									Dyslexia awareness workshop
								</li>
                                		
                                <li>
									<i class="fa fa-check-circle"></i>
                                    Parents and guardians awareness seminars
								</li>
                                <li>
									<i class="fa fa-check-circle"></i>
                                    Schools awareness seminars
								</li>
                                <li>
									<i class="fa fa-check-circle"></i>
                                    Providing learning resources
								</li>
							</ul>
							<div class="about-two__btns">
								<a href="/news" class="thm-btn about-two__btn">
									<span>Discover More</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
        <section class="sec-pad-top sec-pad-bottom">
			<div class="container">
				<div class="row gutter-y-30">
					{Array.isArray(projects) && projects?.map((project) => {
						return (
							<div class="col-md-12 col-lg-4">
						<div class="events-card">
							<div class="events-card__image">
								<img src={project?.fields?.minicover?.fields?.file?.url} alt=""/>
								<img src={project?.fields?.minicover?.fields?.file?.url} class="events-card__image--hover" alt=""/>
							</div>
							<div class="events-card__content">
								<div class="events-card__date">{moment(project?.sys?.createdAt).format('DD')} {moment(project?.sys?.createdAt).format('MMMM')}</div>
								{/* <ul class="events-card__meta list-unstyled">
									<li>
										<i class="fa fa-clock"></i>
										<a href="#">8:00 pm</a>
									</li>
									<li>
										<i class="fa fa-map-marker-alt"></i>
										<a href="#">New York</a>
									</li>
								</ul> */}
								<h3 class="events-card__title"><a href={`/projects/${project?.sys?.id}`}>{project?.fields?.title}</a></h3>
							</div>
						</div>
					</div>
						)
					})}
				</div>
			</div>
		</section>
   </Layout>
  );
}

export default Team;