import React, {useEffect, useState} from 'react';
import Layout from '../homelayout/newlayout';
import { Helmet } from 'react-helmet';
import {useTeams} from '../useContentful';






function Aboutus() {
    const [teams, setTeams] = useState([]);
    const {getTeams} = useTeams();
    
    useEffect(() => {
        getTeams().then((response) => {
            setTeams(response?.items);
        })
    })

  return (
   <Layout>
         <Helmet>
        <title>About | Dyslexia</title>
      </Helmet>
      <section class="page-header" style={{backgroundImage: "url(https://res.cloudinary.com/dedfrilse/image/upload/v1681640262/Banner_image-01.png)"}}>
	  <div className='page-header-opacity'/>
			<div class="container">
				<ul class="list-unstyled breadcrumb-one">
					<li><a href="/">Home</a></li>
					<li><span>About Us</span></li>
				</ul>
				<h2 class="page-header__title">About Us</h2>
			</div>
		</section>
        <section class="sec-pad-top sec-pad-bottom about-four">
			<img src="https://paroti-html.vercel.app/assets/images/shapes/about-4-2.png" alt="" class="float-bob-x about-four__shape"/>
			<div class="container">
				<div class="sec-title text-center">
					<p class="sec-title__tagline">Get to know Us</p>
					<h2 class="sec-title__title">Extend a supportive hand to them</h2>
				</div>

				<div class="row gutter-y-60">
					<div class="col-md-12 col-lg-6">
						<div class="about-four__image wow fadeInLeft" data-wow-duration="1500ms">
							<img src="https://res.cloudinary.com/dedfrilse/image/upload/v1680086615/dsylexia/585x493-About_Page.png" alt=""/>
							{/* <a href="https://www.youtube.com/watch?v=CWCPovmNWK8"
								class="video-popup about-four__video"><i class="fa fa-play"></i></a> */}
						</div>
					</div>
					<div class="col-md-12 col-lg-6">
						<div class="about-four__content">
							<div class="about-four__content__text">Join our community to make a positive impact on the lives of dyslexic children</div>
						
							<ul class="list-unstyled about-four__list">
								<li class="about-four__list__item">
									<i class="fa fa-check-circle"></i>
									<h3 class="about-four__list__title">Our Mission</h3>
								
									<p class="about-four__list__text">Empower dyslexic children through creating awareness and advocating for dyslexia friendly-education.</p>
								
								</li>
								<li class="about-four__list__item">
									<i class="fa fa-check-circle"></i>
									<h3 class="about-four__list__title">Our Vision</h3>
								
									<p class="about-four__list__text">Equal opportunities in education for dyslexic children.</p>
								
								</li>
								{/* <li class="about-four__list__item">
									<i class="fa fa-check-circle"></i>
									<h3 class="about-four__list__title">Donate for them</h3>
									
									<p class="about-four__list__text">Lorem Ipsum nibh vel velit auctor aliquet. Aenean
										sollic tudin, lorem is simply free text quis bibendum.</p>
								
								</li> */}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
        <section class="sec-pad-top sec-pad-bottom about-two" style={{backgroundColor: '#f1f6f7'}}>
			<img src="https://paroti-html.vercel.app/assets/images/shapes/about-2-1.png" class="about-two__shape-1 float-bob-x" alt=""/>
			<div class="container">
				<div class="row gutter-y-60">
					<div class="col-md-12 col-lg-6">
						<div class="about-two__image">
							<div class="about-two__image__shape-1"></div>
							<div class="about-two__image__shape-2"></div>
							<div class="about-two__image__shape-3"></div>
							<img src="https://res.cloudinary.com/dedfrilse/image/upload/v1680086615/dsylexia/500x591-About_Page.png" class="wow fadeInLeft"
								data-wow-duration="1500ms" alt=""/>
							{/* <div class="about-two__image__caption">
								<h3 class="about-two__image__caption__count count-box">
									<span class="count-text" data-stop="30" data-speed="1500"></span>+
								</h3>
								<p class="about-two__image__caption__text">Years of work
									expeirece</p>
							</div> */}
						</div>
					</div>
					<div class="col-md-12 col-lg-6">
						<div class="about-two__content">
							<div class="sec-title">
								<p class="sec-title__tagline">Welcome to our team</p>
								<h2 class="sec-title__title">What we address within our community</h2>
							</div>
							<p class="about-two__text">Our main purpose is to advocate for equal learning opportunities for dyslexic children and creating awareness about dyslexia.</p>
							<ul class="list-unstyled about-two__info">
								<li class="about-two__info__item" style={{"--accent-color": "#8139e7"}}>
									<i class="paroti-icon-solidarity"></i>
									<h3 class="about-two__info__title">Our Values</h3>
								</li>
							</ul>
							<ul class="list-unstyled about-two__list">
								<li>
									<i class="fa fa-check-circle"></i>
									Equality
								</li>
                                		
                                <li>
									<i class="fa fa-check-circle"></i>
                                    Inclusivity
								</li>
                                <li>
									<i class="fa fa-check-circle"></i>
                                    Fairness in education	
								</li>
                                <li>
									<i class="fa fa-check-circle"></i>
                                    Positivity
								</li>
							</ul>
							{/* <div class="about-two__btns">
								<a href="/news" class="thm-btn about-two__btn">
									<span>Discover More</span>
								</a>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</section>
        <section class="sec-pad-top cta-one cta-one--pad-bottom">
			<div class="cta-one__bg" style={{backgroundImage: "url(https://paroti-html.vercel.app/assets/images/backgrounds/cta-bg-1-1.jpg)"}}></div>
			{/* <div class="cta-one__shape" style={{backgroundImage: "url(https://paroti-html.vercel.app/assets/images/shapes/cta-s-1-1.png)"}}>
			</div> */}
			
			<div class="container  text-center">
				<div class="sec-title">
					<p class="sec-title__tagline">Let's stand for dyslexic children </p>
					<h2 class="sec-title__title">We stand with teachers, parents and<br/>
						<span>guardians</span> to make a difference</h2>
				</div>
				<a href="/contactus" class="thm-btn cta-one__btn"><span>JOIN OUR TEAM</span></a>
			</div>
		</section>
      
        <section class="sec-pad-top sec-pad-bottom">
			<div class="container">
				<div class="sec-title text-center">
					<p class="sec-title__tagline">Get to know Us</p>
					<h2 class="sec-title__title">Our Team</h2>
				</div>
				<div class="row gutter-y-30">
					{teams?.length > 0 && teams?.map((team) => {
						return (
							<div class="col-md-6 col-lg-4">
						<div class="volunteers-card" style={{backgroundColor: `${team?.fields?.colorcode || '#1B89C7'}`}}>
							<h3 class="volunteers-card__title"><a href="#">{team?.fields?.name}</a></h3>
							<p class="volunteers-card__designation">{team?.fields?.jobtitle}</p>
							<div class="volunteers-card__image">
								<img src={team?.fields?.profilephoto?.fields?.file?.url} alt=""/>
							</div>
							<div class="volunteers-card__social">
								<a target="_blank" href={`${team?.fields?.twitterlink}`}><i class="fab fa-twitter"></i></a>
								<a target="_blank" href={`${team?.fields?.facebooklink}`}><i class="fab fa-facebook"></i></a>
								<a target="_blank" href={`${team?.fields?.linkedinlink}`}><i class="fab fa-linkedin"></i></a>
								<a target="_blank" href={`${team?.fields?.instagramlink}`}><i class="fab fa-instagram"></i></a>
							</div>
						</div>
					</div>
						)
					})}
				</div>
			</div>
		</section>
   </Layout>
  );
}

export default Aboutus;