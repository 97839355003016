import React, { useEffect, useState } from 'react';
import Layout from '../homelayout/newlayout';
import {useParams} from 'react-router-dom';
import { useSinglenews } from '../useContentful';
import moment from 'moment';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';




function Servicesdetails() {
	let { newsid } = useParams();
    const [project, setProject] = useState({});
	const [loading, setLoading] = useState(false);
	const {getSinglenews} = useSinglenews();
	useEffect(() => {
        setLoading(true);
		getSinglenews(newsid).then((response) => {
			console.log(response?.fields);
            setLoading(false)
			setProject(response?.fields);
		})
	}, [newsid])

	const options = {
		renderNode: {
		  "embedded-asset-block": (node) => {
			const { title, description, file } = node.data.target.fields;
			const imageUrl = file.url;
			const imageTitle = title ? title['en-US'] : '';
			const imageDescription = description ? description['en-US'] : '';
			return (
			  <img src={imageUrl} alt={imageTitle} title={imageDescription} />
			);
		  },
		},
	  };

  return (
   <Layout>
    <section class="page-header">
			<div class="container">
				<ul class="list-unstyled breadcrumb-one">
					<li><a href="index.html">Home</a></li>
					<li><span>News & Article</span></li>
				</ul>
				<h2 class="page-header__title">{project?.title}</h2>
			</div>
		</section>
		<section class="sec-pad-top sec-pad-bottom blog-details">
			<div class="container">
				<div class="row gutter-y-60">
				<div class="col-lg-2"/>
					<div class="col-lg-8">
						<div class="blog-details__content clearfix">
							<div class="blog-details__image">
								<img src={project?.coverphoto?.fields?.file?.url} alt=""/>
								<div class="blog-card__date">
									<span>{moment(project?.sys?.createdAt).format('DD')}</span>{moment(project?.sys?.createdAt).format('MMMM')}
								</div>
							</div>
							<ul class="blog-card__meta list-unstyled">
								<li>
									{/* <i class="fa fa-user"></i> */}
									<a href="#"></a>
								</li>
								<li>
									{/* <i class="fa fa-comments"></i> */}
									<a href="#"></a>
								</li>
							</ul>
							<div>
							{documentToReactComponents(project?.content, options)}
							</div>
						</div>
						{/* <div class="blog-details__bottom">
							<p class="blog-details__tags">
								<span>Tags</span>
								<a href="#">Donation</a>
								<a href="#">Charity</a>
							</p>
							<div class="blog-details__social">
								<a href="#"><i class="fab fa-twitter"></i></a>
								<a href="#"><i class="fab fa-facebook"></i></a>
								<a href="#"><i class="fab fa-pinterest"></i></a>
								<a href="#"><i class="fab fa-instagram"></i></a>
							</div>
						</div> */}
						{/* <div class="blog-details__author clearfix">
							<img src="assets/images/blog/blog-a-1.jpg" alt=""/>
							<h3 class="blog-details__author__name">Kevin martin</h3>
							<p class="blog-details__author__bio">Cursus massa at urnaaculis estie. Sed aliquamellus
								vitae ultrs condmentum lightly believable. If you are going to use a of you need to be
								sure there isn't anything embarrassing.</p>
						</div> */}
						{/* <div class="blog-details__comments">
							<h3 class="blog-details__sec__title">02 Comments</h3>
							<ul class="list-unstyled blog-details__comments__list">
								<li>
									<img src="assets/images/blog/blog-c-1.jpg" alt=""/>
									<div class="blog-details__comments__meta">
										<h3 class="blog-details__comments__name">Christine Eve </h3>
								
										<p class="blog-details__comments__date">20 Aug, 2022</p>
										
									</div>
									<p class="blog-details__comments__text">Sending love. My nephews Nick and Anthony
										Salaber are your teammates, so I know the caliber person you are. Our whole
										family is sending our best to you and your family.</p>
									
									<a href="#" class="thm-btn blog-details__comments__btn"><span>Reply</span></a>
								</li>
								<li>
									<img src="assets/images/blog/blog-c-2.jpg" alt=""/>
									<div class="blog-details__comments__meta">
										<h3 class="blog-details__comments__name">Christine Eve </h3>
									
										<p class="blog-details__comments__date">20 Aug, 2022</p>
									
									</div>
									<p class="blog-details__comments__text">Sending love. My nephews Nick and Anthony
										Salaber are your teammates, so I know the caliber person you are. Our whole
										family is sending our best to you and your family.</p>
									
									<a href="#" class="thm-btn blog-details__comments__btn"><span>Reply</span></a>
								</li>
							</ul>
						</div> */}
						{/* <div class="blog-details__form">
							<h3 class="blog-details__sec__title">Leave a comment</h3>
							<form action="assets/inc/sendemail.php" class="contact-one__form contact-form-validated">
								<div class="row">
									<div class="col-md-6">
										<input type="text" placeholder="Your name" name="name"/>
									</div>
									<div class="col-md-6">
										<input type="text" placeholder="Email address" name="email"/>
									</div>
									<div class="col-md-12">
										<textarea name="message" placeholder="Write a message"></textarea>
									</div>
									<div class="col-md-12">
										<button type="submit" class="thm-btn contact-one__btn"><span>Submit
												comment</span></button>
									</div>
								</div>
							</form>
							<div class="result"></div>

						</div> */}
					</div>
					<div class="col-lg-2"/>
					{/* <div class="col-lg-4">
						<div class="sidebar">
							<div class="sidebar__single sidebar__single--search">
								<form action="#">
									<input type="text" placeholder="Search here.."/>
									<button type="submit"><i class="paroti-icon-magnifying-glass"></i></button>
								</form>
							</div>
							<div class="sidebar__single sidebar__single--posts">
								<h3 class="sidebar__title">Recent posts</h3>
								<ul class="list-unstyled sidebar__post">
									<li><a href="#">
											<img src="assets/images/blog/blog-s-1-1.jpg" alt=""/>
											<span class="sidebar__post__meta">
												<i class="fa fa-comments"></i>
												02 comments
											</span>
											<span class="sidebar__post__title">Learn how access to
												clean the water</span>
										</a></li>
									<li><a href="#">
											<img src="assets/images/blog/blog-s-1-2.jpg" alt=""/>
											<span class="sidebar__post__meta">
												<i class="fa fa-comments"></i>
												02 comments
											</span>
											<span class="sidebar__post__title">Learn how access to
												clean the water</span>
										</a></li>
									<li><a href="#">
											<img src="assets/images/blog/blog-s-1-3.jpg" alt=""/>
											<span class="sidebar__post__meta">
												<i class="fa fa-comments"></i>
												02 comments
											</span>
											<span class="sidebar__post__title">Learn how access to
												clean the water</span>
										</a></li>
								</ul>
							</div>
							<div class="sidebar__single sidebar__single--lists">
								<h3 class="sidebar__title">Categories</h3>
								<ul class="list-unstyled sidebar__lists">
									<li><a href="#">Charity</a></li>
									<li><a href="#">Fundrising</a></li>
									<li><a href="#">Donations</a></li>
									<li><a href="#">Save lifes</a></li>
									<li><a href="#">Healthy food</a></li>
								</ul>
							</div>
							<div class="sidebar__single sidebar__single--tags">
								<h3 class="sidebar__title">Tags</h3>
								<p class="sidebar__tags">
									<a href="#">Donation</a>
									<a href="#">Fundraising</a>
									<a href="#">Education</a>
									<a href="#">Charity</a>
									<a href="#">Life</a>
								</p>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</section>
	    
   </Layout>
  );
}

export default Servicesdetails;