import React, { useEffect, useState } from 'react';
import Layout from '../homelayout/newlayout';
import { useNews } from '../useContentful';
import moment from 'moment';





function Services() {
	const [news, setNews] = useState([]);
    const {getNews} = useNews();
	let $ = window?.jQuery;
    let jQuery = window?.jQuery;
    let Swiper = window.Swiper;
    
    useEffect(() => {
        getNews().then((response) => {
            setNews(response?.items);
        })
    }, [])
  return (
   <Layout>
     		<section class="page-header" style={{backgroundImage: "url(https://res.cloudinary.com/dedfrilse/image/upload/v1681640262/Banner_image-01.png)"}}>
			 <div className='page-header-opacity'/>
			<div class="container">
				<ul class="list-unstyled breadcrumb-one">
					<li><a href="index.html">Home</a></li>
					<li><span>News & Articles</span></li>
				</ul>
				<h2 class="page-header__title">News & Articles</h2>
			</div>
		</section>
		<section class="sec-pad-top sec-pad-bottom">
			<div class="container">
				<div class="row gutter-y-30">
				{Array.isArray(news) && news?.map((n) => {
							return (
					<div class="col-sm-12 col-md-6 col-lg-4">
						<div class="blog-card">
							<div class="blog-card__image">
								<img src={n?.fields?.minicover?.fields?.file?.url} alt=""/>
								<div class="blog-card__date">
								<span>{moment(n?.sys?.createdAt).format('DD')}</span>{moment(n?.sys?.createdAt).format('MMMM')}
								</div>
							</div>
							<div class="blog-card__content">
								{/* <ul class="blog-card__meta list-unstyled">
									<li>
										<i class="fa fa-user"></i>
										<a href="#">by Admin</a>
									</li>
									<li>
										<i class="fa fa-comments"></i>
										<a href="#">02 comments</a>
									</li>
								</ul> */}
								<h3 class="blog-card__title"><a href={`/news/${n?.sys?.id}`}>{n?.fields?.title}</a></h3>
								<a href={`/news/${n?.sys?.id}`} class="blog-card__links">
									<i class="fa fa-angle-double-right"></i>
									Read More</a>
							</div>
						</div>
					</div>
							)})}
				</div>
			</div>
		</section>
   </Layout>
  );
}

export default Services;