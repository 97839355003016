import React, {useRef, useState} from 'react';
import Layout from '../homelayout/newlayout';
import { Helmet } from 'react-helmet';
import emailjs, { send } from "@emailjs/browser";





function Donations() {
	const [loading, setLoading] = useState(false);
	let $ = window?.jQuery;
	const form = useRef();
	const sendEmail = (e) => {
		e.preventDefault();
		setLoading(true);
		emailjs.sendForm("service_sj0oofp", "template_39g77mv", form.current, "Fwpkql-nblHxN-LDm").then(
			(result) => {
				setLoading(false);
				$('.modal')?.modal('show');
				// alert("Message Sent Successfully");
			},
			(error) => {
				setLoading(false);
				console.log(error.text);
			}
			);
	};

	return (
		<Layout>
		<Helmet>
		<title>Donations | Dyslexia</title>
		</Helmet>
		<div class="modal" tabindex="-1">
		<div class="modal-dialog">
		<div class="modal-content">
		<div class="modal-header">
		<h5 class="modal-title">Success</h5>
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		</div>
		<div class="modal-body">
		<p>Message submitted successfully.</p>
		</div>
		<div class="modal-footer">
		<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
		</div>
		</div>
		</div>
		</div>
		<section class="page-header" style={{backgroundImage: "url(https://res.cloudinary.com/dedfrilse/image/upload/v1681640262/Banner_image-01.png)"}}>
		<div className='page-header-opacity'/>
		<div class="container">
		<ul class="list-unstyled breadcrumb-one">
		<li><a href="/">Home</a></li>
		<li><span>Donations</span></li>
		</ul>
		<h2 class="page-header__title">Donations</h2>
		</div>
		</section>
		<section class="sec-pad-top sec-pad-bottom about-four">
		<img src="https://paroti-html.vercel.app/assets/images/shapes/about-4-2.png" alt="" class="float-bob-x about-four__shape"/>
		<div class="container">
		<div class="sec-title text-center">
		<p class="sec-title__tagline">Our Community</p>
		<h2 class="sec-title__title">Join Hands with Us</h2>
		</div>

		<div class="row gutter-y-60">
		<div class="col-md-12 col-lg-6">
		<div class="about-four__image wow fadeInLeft" data-wow-duration="1500ms">
		<img src="https://res.cloudinary.com/dedfrilse/image/upload/v1681800146/dsylexia/PICTURE-01.png" alt=""/>
							{/* <a href="https://www.youtube.com/watch?v=CWCPovmNWK8"
								class="video-popup about-four__video"><i class="fa fa-play"></i></a> */}
		</div>
		</div>
		<div class="col-md-12 col-lg-6">
		<div class="about-four__content">
		<div class="about-four__content__text">Together, we can provide a supportive and inclusive environment for dyslexic children.</div>

		<ul class="list-unstyled about-four__list">
		<li class="about-four__list__item">
		<i class="fa fa-check-circle"></i>
		<h3 class="about-four__list__title">Membership</h3>

		<p class="about-four__list__text">Membership is voluntary and open to any Tanzanian who has attained the age of 18 years and above, of sound mind and who is ready to join hands in supporting and promoting vision, mission and objectives of the Organization.</p>

		</li>
		<li class="about-four__list__item">
		<i class="fa fa-check-circle"></i>
		<h3 class="about-four__list__title">Donations</h3>

		<p class="about-four__list__text">Collaborate in funding specialized programs, technology, and tools to help dyslexic children improve their reading and writing abilities, build their confidence, and achieve academic success.</p>

		</li>
		{/* <li class="about-four__list__item">
		<i class="fa fa-check-circle"></i>
		<h3 class="about-four__list__title">Donate for them</h3>

		<p class="about-four__list__text">Lorem Ipsum nibh vel velit auctor aliquet. Aenean
		sollic tudin, lorem is simply free text quis bibendum.</p>

								</li> */}
		</ul>
		</div>
		</div>
		</div>
		</div>
		</section>
		<section class="sec-pad-top sec-pad-bottom gofundme-section">
		<div class="container">
		<div class="row gutter-y-60">
		<div class="col-md-12">
		<div class="text-center">
		<p class="sec-title__tagline">Your contribution matters!</p>
		<h2 class="sec-title__title">Support Us on GoFundMe</h2>
		<p class="about-four__content__text">We appreciate your support in helping dyslexic children thrive.</p>
		<div class="gofundme-link">
		<a href="https://gofund.me/00a1296e" class="thm-btn thm-btn--two thm-btn--light">
		<span>Donate Now</span>
		<i class="fa fa-heart"></i>
		</a>
		</div>
		</div>
		</div>
		</div>
		</div>
		</section>
		<section class="sec-pad-top sec-pad-bottom video-one">
		<div class="container">
		<div class="video-one__inner"
		style={{backgroundImage: "url(https://paroti-html.vercel.app/assets/images/backgrounds/video-inner-bg-1-1.jpg)"}}>
		<div class="row gutter-y-60">
		<div class="col-md-12 col-lg-6">
		<div class="video-one__content">
		<a href="https://www.youtube.com/watch?v=mqwXSNxVKv8"
		class="video-one__btn video-popup">
		<i class="fa fa-play"></i>
		<span class="video-one__btn__arrow"></span>
		</a>
		<h3 class="video-one__title count-box">Together,<br/>we can make a difference</h3>
		<ul class="list-unstyled video-one__list">
		<li>
		<i class="fa fa-check-circle"></i>
		Become a member of Dyslexia Tanzania
		</li>
		<li>
		<i class="fa fa-check-circle"></i>
		Volunteer in our programs and workshops
		</li>
		<li>
		<i class="fa fa-check-circle"></i>
		Donate for dyslexic children
		</li>
		</ul>
		</div>
		</div>
		<div class="col-md-12 col-lg-6">
		<form ref={form} onSubmit={(e) => sendEmail(e)} validate
		class=" contact-one__form video-one__form contact-form-validated">
		<div class="row">
		<div class="col-md-6">
		<input type="text" placeholder="Your name" name="from_name" required/>
		</div>
		<div class="col-md-6">
		<input type="text" placeholder="Your phone" name="phonenumber" required/>
		</div>
		<div class="col-md-12">
		<input type="text" placeholder="Email address" name="email" required/>
		</div>
		<div class="col-md-12">
		<input type="text" placeholder="Organization Name" name="organization" required/>
		</div>
		<div class="col-md-12">
		<input type="text" placeholder="Location" name="location" required/>
		</div>
		<div class="col-md-12">
		<select placeholder='How do you want to collaborate?' className='selectinput' name="collaborate" id="collaborate" required>
		<option disabled selected value="How do you want to collaborate?">How do you want to collaborate?</option>
		<option value="Become a member">Become a member</option>
		<option value="Donate">Donate</option>
		<option value="Volunteer">Volunteer</option>
		</select>
		</div>
		<div class="col-md-12">
		<textarea name="message" placeholder="Message" required></textarea>
		</div>
		<div class="col-md-12">
		<button type="submit" class="thm-btn contact-one__btn"><span>{loading ? 'Submitting' : 'Submit now'}</span></button>
		</div>
		</div>

		</form>
		</div>
		</div>
		</div>
		</div>
		</section>
		<section class="sec-pad-top sec-pad-bottom bank-details-section">
		<div class="container">
		<div class="row gutter-y-60">
		<div class="col-md-12">
		<div class="text-center">
		<p class="sec-title__tagline">Contribute directly to our bank account</p>
		<h2 class="sec-title__title">Support Us via Bank Transfer</h2>
		<div class="bank-details">
		<p><strong>Account details:</strong></p>
		<p><strong>Dyslexia Tanzania</strong></p>
		<p><strong>KCB Bank</strong></p>
		<p><strong>USD Account:</strong> 3391113413</p>
		<p><strong>TZS Account:</strong> 3391233958</p>
		</div>
		</div>
		</div>
		</div>
		</div>
		</section>
		</Layout>
		);
}

export default Donations;