import React, { useEffect, useRef, useState } from 'react';
import Layout from '../homelayout/newlayout';
import { Helmet } from 'react-helmet';
import { useClients, useNews } from '../useContentful';
import moment from 'moment';
import emailjs from "@emailjs/browser";

function Homepage() {
	const [news, setNews] = useState([]);
	const [logo, setLogo] = useState([]);
    const {getNews} = useNews();
	const {getClients} = useClients();
	const [loading, setLoading] = useState(false);
	let $ = window?.jQuery;
    let jQuery = window?.jQuery;
    let Swiper = window.Swiper;
    
    useEffect(() => {
        getNews().then((response) => {
            setNews(response?.items);
			loadcarousel();
        })
		getClients().then((response) => {
			console.log(response);
			setLogo(response?.items);
			loadcarousel();
		})
    }, [])

	const loadcarousel = () => {
		let tns = window?.tns;
		let thmTnsCarousels = $(".thm-tns__carousel");
		if (thmTnsCarousels.length) {
			thmTnsCarousels.each(function () {
				let elm = $(this);
				let options = elm.data("tns-options");
				let thmTnsCarousels = "object" === typeof options && tns(
					 options
				);
			});
		}
	
		let thmOwlCarousels = $(".thm-owl__carousel");
		if (thmOwlCarousels.length) {
			thmOwlCarousels.each(function () {
				let elm = $(this);
				let options = elm.data("owl-options");
				let thmOwlCarousel = elm.owlCarousel(
					"object" === typeof options ? options : {}
				);
			});
		}
	
		let thmOwlNavCarousels = $(".thm-owl__carousel--custom-nav");
		if (thmOwlNavCarousels.length) {
			thmOwlNavCarousels.each(function () {
				let elm = $(this);
				let owlNavPrev = elm.data("owl-nav-prev");
				let owlNavNext = elm.data("owl-nav-next");
				$(owlNavPrev).on("click", function (e) {
					elm.trigger("prev.owl.carousel");
					e.preventDefault();
				});
	
				$(owlNavNext).on("click", function (e) {
					elm.trigger("next.owl.carousel");
					e.preventDefault();
				});
			});
		}
	}


const form = useRef();
	const sendEmail = (e) => {
        e.preventDefault();
            setLoading(true);
        emailjs.sendForm("service_sj0oofp", "template_p5y4t5m", form.current, "Fwpkql-nblHxN-LDm").then(
          (result) => {
              setLoading(false);
			  $('.modal')?.modal('show');
            // alert("Message Sent Successfully");
          },
          (error) => {
              setLoading(false);
            console.log(error.text);
          }
        );
      };

  return (
<Layout page='home'>
<Helmet>
        <title>Home | Dyslexia</title>
      </Helmet>
	  <div class="modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Success</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Message submitted successfully.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
	  <section class="slider-one slider-three">
			<div class="thm-owl__carousel owl-carousel owl-theme" data-owl-options={JSON.stringify({
				loop: true,
				autoplay: true,
				autoplayTimeOut: 7000,
				items: 1,
				margin: 0,
				animateIn: "fadeIn",
				animateOut: "slideOutDown",
				nav: true,
				dots: true,
				navText: ["<span class=\"paroti-icon-left-arrow\"></span>","<span class=\"paroti-icon-right-arrow\"></span>"]
			})}>
				<div class="item">
					<div class="slider-one__item">
						<div class="slider-one__image"
							style={{backgroundImage: `url(${'https://res.cloudinary.com/dedfrilse/image/upload/v1680086618/dsylexia/1894x828-01-Home_Page.png'
						})`}}></div>
						
						<div class="container">
							<p class="slider-one__text">Welcome to Dyslexia Tanzania</p>
							
							<h2 class="slider-one__title">Empowering<br/>
							Dyslexic Children</h2>
							<div class="slider-one__btns">
								<a href="/aboutus" class="home-btn thm-btn slider-one__btn">
									<span>Discover More</span>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="slider-one__item">
						<div class="slider-one__image"
							style={{backgroundImage: `url(${'https://res.cloudinary.com/dedfrilse/image/upload/v1680086615/dsylexia/1894x828-02-Home_Page.png'
						})`}}></div>
						
						<div class="container" style={{paddingBottom: 70}}>
							<p class="slider-one__text">Welcome to Dyslexia Tanzania</p>
					
							<h2 class="slider-one__title">Unlocking Potential<br/><p>    </p></h2>
							<div class="slider-one__btns">
								<a href="about.html" class="home-btn thm-btn slider-one__btn">
									<span>Discover More</span>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="slider-one__item">
						<div class="slider-one__image"
							style={{backgroundImage: `url(${'https://res.cloudinary.com/dedfrilse/image/upload/v1680086617/dsylexia/1894x828-03-Home_Page.png'
						})`}}></div>
						
						<div class="container">
							<p class="slider-one__text">Welcome to Dyslexia Tanzania</p>
							
							<h2 class="slider-one__title">Providing Equal<br/>
							Learning Opportunities</h2>
							<div class="slider-one__btns">
								<a href="about.html" class="home-btn thm-btn slider-one__btn">
									<span>Discover More</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="sec-pad-top sec-pad-bottom about-five">
			<div class="container">
				<div class="row gutter-y-60">
					<div class="col-md-12 col-lg-6">
						<div class="about-five__content">
							<div class="sec-title text-start">
								<p class="sec-title__tagline">Ready to join us?</p>
								<h2 class="sec-title__title">Creating a brighter future</h2>
							</div>
							<div class="about-five__text">We address the obstacles that dyslexic children frequently encounter in education, by facilitating the realisation of their full potential and empowering them to succeed.</div>
							<blockquote class="about-five__blockquote">
								<i class="paroti-icon-quote"></i>
								we cannot afford to ignore these very innovative and highly talented members of our community
							</blockquote>
							<div class="about-five__person">
								<div class="about-five__person__image">
									<img src="https://res.cloudinary.com/dedfrilse/image/upload/c_scale,w_75/v1681735723/75x75-01.png" alt=""/>
								</div>
								<div class="about-five__person__content">
									<h3 class="about-five__person__title">Caudence Ayoti</h3>
									
									<p class="about-five__person__designation">FOUNDER</p>

								</div>
							</div>
							<div class="about-five__content__arrow float-bob-x"></div>
							
						</div>
					</div>
					<div class="col-md-12 col-lg-6">
						<div class="row gutter-y-30">
							<div class="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="1500ms"
								data-wow-delay="000ms">
								<div class="about-five__item" style={{"--accent-color": "var(--paroti-base)"}}>
									<div class="about-five__item__icon">
										<i class="paroti-icon-peace"></i>
									</div>
									<h3 class="about-five__item__title"><a href="/contactus">Support</a></h3>
									
									<p class="about-five__item__tagline">They need support</p>
									
								</div>
							</div>
							<div class="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="1500ms"
								data-wow-delay="100ms">
								<div class="about-five__item" style={{"--accent-color": "var(--paroti-secondary)"}}>
									<div class="about-five__item__icon">
										<i class="paroti-icon-praying"></i>
									</div>
									<h3 class="about-five__item__title"><a href="/donation">Donate</a></h3>
									
									<p class="about-five__item__tagline">Start donating now</p>
									
								</div>
							</div>
							<div class="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="1500ms"
								data-wow-delay="200ms">
								<div class="about-five__item" style={{backgroundColor: '#1B89C7'}}>
									<div class="about-five__item__icon">
										<i class="paroti-icon-peace-1"></i>
									</div>
									<h3 class="about-five__item__title"><a href="/contactus">Volunteer</a></h3>
									
									<p class="about-five__item__tagline">JOIN OUR EXPERT TEAM</p>
									
								</div>
							</div>
							<div class="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="1500ms"
								data-wow-delay="300ms">
								<div class="about-five__item" style={{"--accent-color": "#8139e7"}}>
									<div class="about-five__item__icon">
										<i class="paroti-icon-heart"></i>
									</div>
									<h3 class="about-five__item__title"><a href="/contactus">Be a Member</a></h3>
									
									<p class="about-five__item__tagline">JOIN US TODAY</p>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		{/* <section class="sec-pad-top sec-pad-bottom donation-home-3">
			<div class="donation-home-3__shape wow slideInDown" data-wow-duration="1500ms"></div>
			
			<div class="container">
				<div class="sec-title text-center">
					<p class="sec-title__tagline">Start donating them</p>
					<h2 class="sec-title__title">Find popular causes</h2>
				</div>

				<div class="thm-owl__carousel owl-carousel owl-theme donation-home-3__carousel" data-owl-options={JSON.stringify({
				items: 1,
					margin: 0,
					loop: false,
					nav: false,
					dots: false,
					autoplay: false,
					responsive: {
						"0": {
							"items": 1
						},
						"576": {
							"items": 2,
							"margin": 30
						},
						"992": {
							"items": 3,
							"margin": 30
						}
					}
				})}>
					<div class="item">
						<div class="donations-card">
							<div class="donations-card__image">
								<img src="assets/images/donations/donations-1-1.jpg" alt=""/>
								<div class="donations-card__category">
									<a href="#">Medical</a>
								</div>
							</div>
							<div class="donations-card__content">
								<h3 class="donations-card__title"><a href="donations-details.html">Your little help
										can
										heal
										their pains</a></h3>
								<p class="donations-card__text">Aellentesque porttitor lacus quis enim varius sed
									efficitur...</p>
								<div class="bar">
									<div class="bar-inner count-bar--no-appear" data-percent="36%">
										<div class="count-text">36%</div>
									</div>
								</div>
								<div class="donations-card__amount">
									<p>
										<span>$25,270</span> Raised
									</p>
									<p>
										<span>$30,000</span> Goal
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="donations-card" style={{"--accent-color": "#8139e7"}}>
							<div class="donations-card__image">
								<img src="assets/images/donations/donations-1-2.jpg" alt=""/>
								<div class="donations-card__category">
									<a href="#">education</a>
								</div>
							</div>
							<div class="donations-card__content">
								<h3 class="donations-card__title"><a href="donations-details.html">Give african
										childrens
										a
										good education</a></h3>
								<p class="donations-card__text">Aellentesque porttitor lacus quis enim varius sed
									efficitur...</p>
								<div class="bar">
									<div class="bar-inner count-bar--no-appear" data-percent="36%">
										<div class="count-text">36%</div>
									</div>
								</div>
								<div class="donations-card__amount">
									<p>
										<span>$25,270</span> Raised
									</p>
									<p>
										<span>$30,000</span> Goal
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="donations-card" style={{"--accent-color": "#fdbe44"}}>
							<div class="donations-card__image">
								<img src="assets/images/donations/donations-1-3.jpg" alt=""/>
								<div class="donations-card__category">
									<a href="#">Food</a>
								</div>
							</div>
							<div class="donations-card__content">
								<h3 class="donations-card__title"><a href="donations-details.html">Raise funds for
										clean
										&
										healthy food</a></h3>
								<p class="donations-card__text">Aellentesque porttitor lacus quis enim varius sed
									efficitur...</p>
								<div class="bar">
									<div class="bar-inner count-bar--no-appear" data-percent="36%">
										<div class="count-text">36%</div>
									</div>
								</div>
								<div class="donations-card__amount">
									<p>
										<span>$25,270</span> Raised
									</p>
									<p>
										<span>$30,000</span> Goal
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> */}
{/* 
		<section class="faq-one">
			<div class="faq-one__bg" style={{backgroundImage: `url(${'https://paroti-html.vercel.app/assets/images/shapes/donation-2-bg-1-1.png'})`}}></div>
		</section> */}

		{/* <section class="event-home-two sec-pad-top sec-pad-bottom">
			<div class="container">
				<div class="row gutter-y-60">
					<div class="col-xl-3">
						<div class="event-home-two__content">
							<div class="sec-title text-start">
								<p class="sec-title__tagline">Upcoming events</p>
								<h2 class="sec-title__title">Check latest <br/>upcoming<br/>events</h2>
							</div>
							<div class="event-home-two__content__text">There are many variations of passages of lorem
								ipsum available but the majority have suffered.</div>
							
							<div class="event-home-two__btns">
								<a href="contact.html" class="thm-btn event-home-two__btn">
									<span>Register your seat</span>
								</a>
							</div>
						</div>
					</div>
					<div class="col-xl-9">
						<div class="event-home-two__carousel">
							<div class="thm-owl__carousel owl-carousel" data-owl-options={JSON.stringify({
							items: 1,
							loop: true,
							autoplay: true,
							autoplayTimeOut: 5000,
							autoplayHoverPause: true,
							responsive: {
								"0": {
									"items": 1
								},
								"576": {
									"items": 2,
									"margin": 30
								}
							}
						})}>
								<div class="item">
									<div class="events-card">
										<div class="events-card__image">
											<img src="assets/images/events/events-1-1.jpg" alt=""/>
											<img src="assets/images/events/events-1-1.jpg"
												class="events-card__image--hover" alt=""/>
										</div>
										<div class="events-card__content">
											<div class="events-card__date">20 Aug</div>
											<ul class="events-card__meta list-unstyled">
												<li>
													<i class="fa fa-clock"></i>
													<a href="#">8:00 pm</a>
												</li>
												<li>
													<i class="fa fa-map-marker-alt"></i>
													<a href="#">New York</a>
												</li>
											</ul>
											<h3 class="events-card__title"><a href="event-details.html">Play for their
													world
													with us</a></h3>
										</div>
									</div>
								</div>
								<div class="item">
									<div class="events-card">
										<div class="events-card__image">
											<img src="assets/images/events/events-1-2.jpg" alt=""/>
											<img src="assets/images/events/events-1-2.jpg"
												class="events-card__image--hover" alt=""/>
										</div>
										<div class="events-card__content">
											<div class="events-card__date">20 Aug</div>
											<ul class="events-card__meta list-unstyled">
												<li>
													<i class="fa fa-clock"></i>
													<a href="#">8:00 pm</a>
												</li>
												<li>
													<i class="fa fa-map-marker-alt"></i>
													<a href="#">New York</a>
												</li>
											</ul>
											<h3 class="events-card__title"><a href="event-details.html">Play for their
													world
													with us</a></h3>
										</div>
									</div>
								</div>
								<div class="item">
									<div class="events-card">
										<div class="events-card__image">
											<img src="assets/images/events/events-1-3.jpg" alt=""/>
											<img src="assets/images/events/events-1-3.jpg"
												class="events-card__image--hover" alt=""/>
										</div>
										<div class="events-card__content">
											<div class="events-card__date">20 Aug</div>
											<ul class="events-card__meta list-unstyled">
												<li>
													<i class="fa fa-clock"></i>
													<a href="#">8:00 pm</a>
												</li>
												<li>
													<i class="fa fa-map-marker-alt"></i>
													<a href="#">New York</a>
												</li>
											</ul>
											<h3 class="events-card__title"><a href="event-details.html">Play for their
													world
													with us</a></h3>
										</div>
									</div>
								</div>
								<div class="item">
									<div class="events-card">
										<div class="events-card__image">
											<img src="assets/images/events/events-1-4.jpg" alt=""/>
											<img src="assets/images/events/events-1-4.jpg"
												class="events-card__image--hover" alt=""/>
										</div>
										<div class="events-card__content">
											<div class="events-card__date">20 Aug</div>
											<ul class="events-card__meta list-unstyled">
												<li>
													<i class="fa fa-clock"></i>
													<a href="#">8:00 pm</a>
												</li>
												<li>
													<i class="fa fa-map-marker-alt"></i>
													<a href="#">New York</a>
												</li>
											</ul>
											<h3 class="events-card__title"><a href="event-details.html">Play for their
													world
													with us</a></h3>
										</div>
									</div>
								</div>
								<div class="item">
									<div class="events-card">
										<div class="events-card__image">
											<img src="assets/images/events/events-1-5.jpg" alt=""/>
											<img src="assets/images/events/events-1-5.jpg"
												class="events-card__image--hover" alt=""/>
										</div>
										<div class="events-card__content">
											<div class="events-card__date">20 Aug</div>
											<ul class="events-card__meta list-unstyled">
												<li>
													<i class="fa fa-clock"></i>
													<a href="#">8:00 pm</a>
												</li>
												<li>
													<i class="fa fa-map-marker-alt"></i>
													<a href="#">New York</a>
												</li>
											</ul>
											<h3 class="events-card__title"><a href="event-details.html">Play for their
													world
													with us</a></h3>
										</div>
									</div>
								</div>
								<div class="item">
									<div class="events-card">
										<div class="events-card__image">
											<img src="assets/images/events/events-1-6.jpg" alt=""/>
											<img src="assets/images/events/events-1-6.jpg"
												class="events-card__image--hover" alt=""/>
										</div>
										<div class="events-card__content">
											<div class="events-card__date">20 Aug</div>
											<ul class="events-card__meta list-unstyled">
												<li>
													<i class="fa fa-clock"></i>
													<a href="#">8:00 pm</a>
												</li>
												<li>
													<i class="fa fa-map-marker-alt"></i>
													<a href="#">New York</a>
												</li>
											</ul>
											<h3 class="events-card__title"><a href="event-details.html">Play for their
													world
													with us</a></h3>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</section> */}

		<section class="about-six about-six--pad-top about-six--pad-bottom">
			<div class="about-six__bg">
			</div>
			<div class="about-six__shape float-bob-x"
				style={{backgroundImage: "url(https://paroti-html.vercel.app/assets/images/shapes/about-six-s-1.png)"}}></div>
			
			<div class="container">
				<div class="row gutter-y-60">
					<div class="col-lg-6">
						<div class="about-six__images">
							<img src="https://res.cloudinary.com/dedfrilse/image/upload/v1680086616/dsylexia/370x494-Home_Page.png" alt=""/>
							<img src="https://res.cloudinary.com/dedfrilse/image/upload/v1680088366/dsylexia/303x244-Home_Page.png" alt=""/>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="about-six__content">
							<div class="sec-title text-start">
								<p class="sec-title__tagline">Understand Dyslexia</p>
								<h2 class="sec-title__title">What is Dyslexia?</h2>
							</div>
							<div class="about-six__content__text">Dyslexia is a specific learning difficulty that primarily affects reading and spelling, and is often associated with challenges in phonological awareness, verbal memory, and processing speed.</div>
						
							<ul class="list-unstyled about-six__list">
								<li class="about-six__list__item">
									<i class="about-six__list__icon fa fa-check-circle"></i>
									<h3 class="about-six__list__title">Did You Know?</h3>
									
									<p class="about-six__list__text">Dyslexia affects approximately 10% of the world's total population, irrespective of age, gender, race, or intelligence level.</p>
								</li>
								<li class="about-six__list__item">
									<i class="about-six__list__icon fa fa-check-circle"></i>
									<h3 class="about-six__list__title">Global Fact</h3>
								
									<p class="about-six__list__text">
Dyslexia is not related to a lack of intelligence, as dyslexics can have average or above-average intelligence.</p>
								</li>
							</ul>
							<div class="about-six__btns">
								<a href="/aboutus" class="thm-btn about-six__btn">
									<span>Learn More</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>


		<section class="about-three" >
			<div class="about-three__shape wow slideInLeft" data-wow-duration="1500ms"></div>
		
			<div class="container">
				<div class="row gutter-y-60">
					<div class="col-md-12 col-lg-5">
						<div class="about-three__content">
							<div class="sec-title home-padding">
								<p class="sec-title__tagline">All support to them</p>
								<h2 class="sec-title__title">Embrace Dyslexia</h2>
							</div>
							{/* <div class="about-three__text">There are many variations of passages of available, but the
								majority have suffered alteration in some form.</div> */}
							<ul class="about-three__list list-unstyled">
								<li class="about-three__list__item">
									<div class="about-three__list__icon">
										<i class="paroti-icon-charity"></i>
									</div>
									{/* <h3 class="about-three__list__title">We help them</h3> */}
									
									<p class="about-three__list__text">It is important to understand that dyslexia is not a sign of laziness or lack of intelligence,but rather a learning difference that requires a different approach to teaching.</p>
								</li>
								<li class="about-three__list__item" style={{"--accent-color": "#fdbe44"}}>
									<div class="about-three__list__icon">
										<i class="paroti-icon-education"></i>
									</div>
									{/* <h3 class="about-three__list__title">We help them</h3> */}
									
									<p class="about-three__list__text">Early identification and intervention can be critical in helping dyslexic individuals to overcome challenges and reach their full potential.</p>
								</li>
							</ul>

						</div>
					</div>
					<div class="col-md-12 col-lg-7">
						<div class="about-three__image">
							<img src="https://res.cloudinary.com/dedfrilse/image/upload/v1680086615/dsylexia/882x482-Home_Page.png" alt=""/>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="sec-pad-top sec-pad-bottom about-two" style={{backgroundColor: '#f1f6f7'}}>
			<img src="assets/images/shapes/about-2-1.png" class="about-two__shape-1 float-bob-x" alt=""/>
			<div class="container">
				<div class="row gutter-y-60">
					<div class="col-md-12 col-lg-6">
						<div class="about-two__image">
							<div class="about-two__image__shape-1"></div>
							<div class="about-two__image__shape-2"></div>
							<div class="about-two__image__shape-3"></div>
							<img src="https://res.cloudinary.com/dedfrilse/image/upload/v1680086615/dsylexia/500x591-Home_Page.png" class="wow fadeInLeft"
								data-wow-duration="1500ms" alt=""/>
							{/* <div class="about-two__image__caption">
								<h3 class="about-two__image__caption__count count-box">
									<span class="count-text" data-stop="30" data-speed="1500"></span>+
								</h3>
								<p class="about-two__image__caption__text">Years of work
									expeirece</p>
							</div> */}
						</div>
					</div>
					<div class="col-md-12 col-lg-6">
						<div class="about-two__content">
							<div class="sec-title">
								<p class="sec-title__tagline">Welcome to our community</p>
								<h2 class="sec-title__title">Become a member of our Community</h2>
							</div>
							<p class="about-two__text" style={{marginTop: -20, marginBottom: 20}}>Get access to resources, workshops, and training programs, that will help you build your knowledge and skills in supporting dyslexics.</p>
							{/* <ul class="list-unstyled about-two__info">
								<li class="about-two__info__item">
									<i class="paroti-icon-sponsor"></i>
									<h3 class="about-two__info__title">Let’s sponsor an
										entire project</h3>
								</li>
								<li class="about-two__info__item" style={{"--accent-color": "#8139e7"}}>
									<i class="paroti-icon-solidarity"></i>
									<h3 class="about-two__info__title">Donate to the
										new cause</h3>
								</li>
							</ul>
							<ul class="list-unstyled about-two__list">
								<li>
									<i class="fa fa-check-circle"></i>
									If you are going to use a passage of you need.
								</li>
								<li>
									<i class="fa fa-check-circle"></i>
									Lorem ipsum available, but the majority have suffered.
								</li>
							</ul> */}
							<div class="about-two__btns">
								<a href="/aboutus" class="thm-btn about-two__btn">
									<span>Discover More</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

	 <section class="sec-pad-top blog-home-3">
			<div class="container">
				<div class="sec-title text-center">
					<p class="sec-title__tagline">Read our latest stories</p>
					<h2 class="sec-title__title">Latest News & Insights</h2>
				</div>
				<div class="blog-carousel">
					<div class="thm-tns__carousel " id="blog-carousel-1" data-tns-options={JSON.stringify({
					container: "#blog-carousel-1",
					loop: true,
					autoplay: true,
					items: 1,
					gutter: 0,
					mouseDrag: true,
					touch: true,
					nav: false,
					autoplayButtonOutput: false,
					controls: false,
					responsive: {
						"0": {
							"items": 1,
							"gutter": 0
						},
						"576": {
							"items": 1,
							"gutter": 0
						},
						"768": {
							"items": 2,
							"gutter": 30
						},
						"992": {
							"items": 3,
							"gutter": 30
						},
						"1200": {
							"items": 3,
							"gutter": 30
						}
					}})}>
						{Array.isArray(news) && news?.map((n) => {
							return (
								<div class="item">
							<div class="blog-card">
								<div class="blog-card__image">
									<img src={n?.fields?.minicover?.fields?.file?.url} alt=""/>
									<div class="blog-card__date">
										<span>{moment(n?.sys?.createdAt).format('DD')}</span>{moment(n?.sys?.createdAt).format('MMMM')}
									</div>
								</div>
								<div class="blog-card__content">
									{/* <ul class="blog-card__meta list-unstyled">
										<li>
											<i class="fa fa-user"></i>
											<a href="#">by Admin</a>
										</li>
										<li>
											<i class="fa fa-comments"></i>
											<a href="#">02 comments</a>
										</li>
									</ul> */}
									<h3 class="blog-card__title"><a href={`/news/${n?.sys?.id}`} >{n?.fields?.title}</a></h3>
									<a href={`/news/${n?.sys?.id}`} class="blog-card__links">
										<i class="fa fa-angle-double-right"></i>
										Read More</a>
								</div>
							</div>
						</div>
							)
						})}
					</div>
				</div>
			</div>
		</section>
                {/**frequest section */}
			 <div class="container" style={{marginTop: 50, marginBottom: 50}}>
				<div class="row gutter-y-60">
					<div class="col-lg-6">
						<div class="faq-one__content">
							<div class="sec-title text-start">
								<p class="sec-title__tagline">Questions and answers</p>
								<h2 class="sec-title__title">Frequently Asked Questions</h2>
							</div>
							<div class="faq-one__content__text">Get to know the answers of common questions asked</div>
							
							<div class="accordion faq-one__accordion" id="faq-one__accordion-1">
								<div class="accordion-item faq-one__accordion__item">
									<h2 class="accordion-header faq-one__accordion__header"
										id="faq-one__accordion-1__heading-1">
										<button class="accordion-button faq-one__accordion__button" type="button"
											data-bs-toggle="collapse" data-bs-target="#faq-one__accordion-1__collapse-1"
											aria-expanded="true" aria-controls="faq-one__accordion-1__collapse-1">
											Where are you located?
											<span class="faq-one__accordion__icon"></span>
											
										</button>
									</h2>
								<div id="faq-one__accordion-1__collapse-1"
										class="accordion-collapse collapse show faq-one__accordion__collapse"
										aria-labelledby="faq-one__accordion-1__heading-1"
										data-bs-parent="#faq-one__accordion-1">
										<div class="accordion-body faq-one__accordion__body">Tanzanite street, House Number 13, nearby Canossa Primary, Arusha Tanzania.</div>
									</div>
								</div>
								<div class="accordion-item faq-one__accordion__item">
									<h2 class="accordion-header faq-one__accordion__header"
										id="faq-one__accordion-1__heading-2">
										<button class="accordion-button faq-one__accordion__button collapsed"
											type="button" data-bs-toggle="collapse"
											data-bs-target="#faq-one__accordion-1__collapse-2" aria-expanded="false"
											aria-controls="faq-one__accordion-1__collapse-2">
											How do I learn more about Dyslexia?
											<span class="faq-one__accordion__icon"></span>
										</button>
									</h2>
									<div id="faq-one__accordion-1__collapse-2"
										class="accordion-collapse faq-one__accordion__collapse collapse"
										aria-labelledby="faq-one__accordion-1__heading-2"
										data-bs-parent="#faq-one__accordion-1">
										<div class="accordion-body faq-one__accordion__body">
You can learn more by easily contacting us through the form below.</div>
									</div>
								</div>
								<div class="accordion-item faq-one__accordion__item">
									<h2 class="accordion-header faq-one__accordion__header"
										id="faq-one__accordion-1__heading-3">
										<button class="accordion-button faq-one__accordion__button collapsed"
											type="button" data-bs-toggle="collapse"
											data-bs-target="#faq-one__accordion-1__collapse-3" aria-expanded="false"
											aria-controls="faq-one__accordion-1__collapse-3">
											Can adults have dyslexia?
											<span class="faq-one__accordion__icon"></span>
										</button>
									</h2>
									<div id="faq-one__accordion-1__collapse-3"
										class="accordion-collapse faq-one__accordion__collapse collapse"
										aria-labelledby="faq-one__accordion-1__heading-3"
										data-bs-parent="#faq-one__accordion-1">
										<div class="accordion-body faq-one__accordion__body">
										Yes, dyslexia can affect people of all ages.</div>
									</div>
								</div>
								<div class="accordion-item faq-one__accordion__item">
									<h2 class="accordion-header faq-one__accordion__header"
										id="faq-one__accordion-1__heading-4">
										<button class="accordion-button faq-one__accordion__button collapsed"
											type="button" data-bs-toggle="collapse"
											data-bs-target="#faq-one__accordion-1__collapse-4" aria-expanded="false"
											aria-controls="faq-one__accordion-1__collapse-4">
											Is dyslexia related to intelligence?
											<span class="faq-one__accordion__icon"></span>
										</button>
									</h2>
									<div id="faq-one__accordion-1__collapse-4"
										class="accordion-collapse faq-one__accordion__collapse collapse"
										aria-labelledby="faq-one__accordion-1__heading-4"
										data-bs-parent="#faq-one__accordion-1">
										<div class="accordion-body faq-one__accordion__body">
										Dyslexia is not related to intelligence, as individuals with dyslexia can have a wide range of cognitive abilities.</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="faq-one__image">
							<img src="https://res.cloudinary.com/dedfrilse/image/upload/v1680086616/dsylexia/862x767-Home_Page.png" alt=""/>
						</div>
					</div>
				</div>
			</div> 
		
			<section class="sec-pad-top sec-pad-bottom video-one">
			<div class="container">
				<div class="video-one__inner"
					style={{backgroundImage: "url(https://paroti-html.vercel.app/assets/images/backgrounds/video-inner-bg-1-1.jpg)"}}>
					<div class="row gutter-y-60">
						<div class="col-md-12 col-lg-6">
							<div class="video-one__content">
								<a href="https://www.youtube.com/watch?v=mqwXSNxVKv8"
									class="video-one__btn video-popup">
									<i class="fa fa-play"></i>
									<span class="video-one__btn__arrow"></span>
								</a>
								<h3 class="video-one__title count-box">Join us to Learn more <br/>about Dyslexia</h3>
								<ul class="list-unstyled video-one__list">
									<li>
										<i class="fa fa-check-circle"></i>
										Become a part of this heroic mission
									</li>
									<li>
										<i class="fa fa-check-circle"></i>
										Give them a helping hand
									</li>
									<li>
										<i class="fa fa-check-circle"></i>
										Your Contribution makes a big difference
									</li>
								</ul>
							</div>
						</div>
						<div class="col-md-12 col-lg-6">
							<form  ref={form} onSubmit={(e) => sendEmail(e)}
								class=" contact-one__form video-one__form contact-form-validated">
								<div class="row">
									<div class="col-md-6">
										<input type="text" placeholder="Your name" name="from_name" required/>
									</div>
									<div class="col-md-6">
										<input type="text" placeholder="Your phone" name="phonenumber" required/>
									</div>
									<div class="col-md-12">
										<input type="text" placeholder="Email address" name="email" required/>
									</div>
									<div class="col-md-12">
										<input type="text" placeholder="Location" name="location" required/>
									</div>
									<div class="col-md-12">
										<textarea name="message" placeholder="Message" required></textarea>
									</div>
									<div class="col-md-12">
										<button type="submit" class="thm-btn contact-one__btn"><span>{loading ? 'submitting' : 'Submit now'}</span></button>
									</div>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class=" sec-pad-bottom sponsor-carousel  sponsor-carousel--home-3"
			style={{backgroundImage: "url(assets/images/shapes/sponsor-bg-1-1.png)"}}>
				<div className='container' style={{textAlign: 'center', paddingTop: 50, paddingBottom: 20}}>
				<h2 class="sec-title__title">Collaborators</h2>	
				</div>
			<div class="container">
				<div class="thm-tns__carousel" id="sponsor-carousel-1" data-tns-options={JSON.stringify({
					container: "#sponsor-carousel-1",
					loop: true,
					autoplay: true,
					items: 2,
					gutter: 30,
					mouseDrag: true,
					touch: true,
					nav: false,
					autoplayButtonOutput: false,
					controls: false,
					responsive: {
						"0": {
							"items": 2,
							"gutter": 30
						},
						"576": {
							"items": 3,
							"gutter": 30
						},
						"768": {
							"items": 4,
							"gutter": 30
						},
						"992": {
							"items": 5,
							"gutter": 50
						},
						"1200": {
							"items": 5,
							"gutter": 140
						}
					}
				})}>
					{Array.isArray(logo) && logo?.map((log) => {
						return (
							<div class="item">
						<img src={log?.fields?.logo?.fields?.file?.url} alt={log?.fields?.title}/>
					</div>
						)
					})}
					
				</div>
			</div>
		</section>
   </Layout>
  );
}

export default Homepage;