import React from 'react'
import { Switch, Route } from 'react-router-dom'
import First from './components/newhomepage';
import Aboutus from './components/aboutustwo';
import Team from './components/team';
import Donations from './components/donations';
import Contactus from './components/contactustwo';
import News from './components/services';
import Newsdetails from './components/servicesdetails';
import Projectsdetails from './components/projectsdetails';


const App = () => {
return (
  <Switch>
    <Route exact path='/'>
    <First />
    </Route>
    <Route exact path='/aboutus'>
    <Aboutus />
    </Route>
    <Route exact path='/projects'>
    <Team />
    </Route>
    <Route path='/projects/:projectid'  render={(props) => {
          return ( <Projectsdetails {...props } /> )
        }} />
    <Route exact path='/contactus'>
    <Contactus />
    </Route> 
    <Route exact path='/donations'>
    <Donations />
    </Route> 
    <Route exact path='/news'>
    <News />
    </Route> 
    <Route path='/news/:newsid'  render={(props) => {
          return ( <Newsdetails {...props } /> )
        }} />
</Switch>
)
}

export default App