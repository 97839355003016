import React, { useRef, useState } from 'react';
import Layout from '../homelayout/newlayout';
import {Helmet} from 'react-helmet';
import emailjs from "@emailjs/browser";

function Contactus() {
const [loading, setLoading] = useState(false);
let $ = window?.jQuery;
const form = useRef();
	const sendEmail = (e) => {
        e.preventDefault();
            setLoading(true);
        emailjs.sendForm("service_sj0oofp", "template_p5y4t5m", form.current, "Fwpkql-nblHxN-LDm").then(
          (result) => {
              setLoading(false);
			  $('.modal')?.modal('show');
            // alert("Message Sent Successfully");
          },
          (error) => {
              setLoading(false);
            console.log(error.text);
          }
        );
      };

  return (
   <Layout page='contactus'>
       <Helmet>
        <title>Contact Us |  Dyslexia</title>
      </Helmet>
	  <div class="modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Success</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Message submitted successfully.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
       
      <section class="page-header" style={{backgroundImage: "url(https://res.cloudinary.com/dedfrilse/image/upload/v1681640262/Banner_image-01.png)"}}>
	  <div className='page-header-opacity'/>
			<div class="container">
				<ul class="list-unstyled breadcrumb-one">
					<li><a href="index.html">Home</a></li>
					<li><span>Contact</span></li>
				</ul>
				<h2 class="page-header__title">Contact us</h2>
			</div>
		</section>
		<section class="contact-info">
			<div class="container">
				<div class="contact-info__inner wow fadeInUp" data-wow-duration="1500ms"
					style={{backgroundImage: "url(assets/images/backgrounds/contact-info-bg-1-1.jpg)"}}>
					<div class="row gutter-y-30">
						<div class="col-lg-4 col-md-12">
							<div class="contact-info__item">
								<div class="contact-info__icon">
									<i class="fas fa-envelope-open"></i>
								</div>
								<p class="contact-info__text">
									<a href="mailto: info@dyslexiatanzania.org"> info@dyslexiatanzania.org</a><br/>
									{/* <a href="mailto:caudytenga@gmail.com">caudytenga@gmail.com</a> */}
								</p>
							</div>
						</div>
						<div class="col-lg-4 col-md-12">
							<div class="contact-info__item">
								<div class="contact-info__icon">
									<i class="fa fa-map"></i>
								</div>
								<p class="contact-info__text">Tanzanite Street, House Number 13, <br/>
                                Arusha, Tanzania.</p>
							</div>
						</div>
						<div class="col-lg-4 col-md-12">
							<div class="contact-info__item">
								<div class="contact-info__icon">
									<i class="fa fa-mobile"></i>
								</div>
								<p class="contact-info__text">
									<a href="tel:+255 688 535 848">+255 688 535 848</a><br/>
									{/* <a href="tel:+255 688 535 848">+255 688 535 848</a> */}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="sec-pad-top sec-pad-bottom contact-one">
			<div class="container">
				<div class="row gutter-y-60">
					<div class="col-lg-4">
						<div class="sec-title">
							<p class="sec-title__tagline">Contact us</p>
							<h2 class="sec-title__title">Love to hear from you</h2>
						</div>
						<p class="contact-one__text">We believe that by working together, we can continue to grow and provide the best support for dyslexic children.</p>
						<div class="contact-one__social">
							<a href="#"><i class="fab fa-twitter"></i></a>
							<a target="_blank" href="https://www.facebook.com/profile.php?id=100090960036619&mibextid=LQQJ4d"><i class="fab fa-facebook"></i></a>
							<a href="#"><i class="fab fa-linkedin"></i></a>
							<a target="_blank" href="https://instagram.com/dyslexia_tanzania?igshid=YmMyMTA2M2Y="><i class="fab fa-instagram"></i></a>
						</div>
					</div>
					<div class="col-lg-8">
						<form onSubmit={(e) => sendEmail(e)} ref={form} validate class="contact-one__form contact-form-validated">
							<div class="row">
								<div class="col-md-6">
									<input type="text" placeholder="Your name" name="from_name" required/>
								</div>
								<div class="col-md-6">
									<input type="text" placeholder="Phone number" name="phonenumber" required/>
								</div>
								<div class="col-md-12">
									<input type="email" placeholder="Email address" name="email" required />
								</div>
								<div class="col-md-12">
									<textarea name="message" placeholder="Write a message" required></textarea>
								</div>
								<div class="col-md-12">
									<button type="submit" class="thm-btn contact-one__btn"><span>{loading ? 'submitting' : 'Sendmessage'}</span></button>
								</div>
							</div>
						</form>
						<div class="result"></div>
					</div>
				</div>
			</div>
		</section>
		<section class="google-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3982.5484198339095!2d36.71223011451606!3d-3.4593242428315385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zM8KwMjcnMzMuNiJTIDM2wrA0Mic1MS45IkU!5e0!3m2!1sen!2stz!4v1679560928738!5m2!1sen!2stz" width="600" height="450" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
		</section>
      
   </Layout>
  );
}

export default Contactus;